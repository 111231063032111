
export default {
  name: 'PageIndex',
  layout: 'frontend',
  data: () => ({
    currentPage: 1,
    rows: 0,
    perPage: 1,
    items: []
  }),
  head: () => ({
    title: 'Aktuality'
  }),
  watch: {
    currentPage (newVal) {
      this.myProvider(newVal)
    }
  },
  created () {
    this.myProvider(this.currentPage)
  },
  methods: {
    async myProvider (currentPage) {
      try {
        const url = `api/front/infos?page=${currentPage}`

        const response = await this.$axios.$get(url)
        this.rows = response.meta.total
        this.items = response.data
      } catch (error) {
        this.items = []
      }
    }
  }
}
